/**
 * 全局过滤器定义，已经在main.js中全局引入
 * vue文件中使用方法：
 * {{ text |  FBindStatus}}
 */
const BindStatusMap = {
  1: "未绑定",
  2: "已绑定",
};
const NoworkMap = {
  1: "连续3天",
  2: "连续3-7天",
  3: "连续7天以上",
  4: "累计10天",
  4: "累计10-30天",
  4: "累计30天以上",
};
const SiteStatusMap = {
  1: "施工中",
  2: "未开工",
  3: "合同终止",
  4: "施工结束",
};
const WorkerTypeMap = {
  1: "工程管家",
  2: "项目经理",
  3: "瓦工",
  4: "木工",
  5: "油工",
  6: "水电工",
  7: "其他",
};
const DeviceStatusMap = {
  0: "全部",
  1: "在线",
  2: "离线",
  3: "未启用",
  4: "故障",
  5: "备用",
  6: "入库",
};
const SiteNeatLevelMap = {
  1: "优",
  2: "良",
  3: "差",
};
const AlarmEventsMap = {
  cameraproblem: "摄像头歪曲",
  noclothes: "未穿工服",
  smoking: "有人吸烟",
};
const SiteAttendanceMap = {
  1: "已打卡",
  2: "未打卡",
};
const SiteDeviceStatusMap = {
  1: "在线",
  2: "不在线",
  // 2: "——",
};
// 是否纠正
const SitcorrectStatus = {
  1: "未纠正",
  2: "纠正中",
  3: "已纠正",
  4: "已取消",
};
const SitneatResult = {
  1: "优",
  2: "良",
  3: "差",
  4: "摄像头歪",
};
// 纠正结果
const SitTypeLog = {
  1: "无人吸烟/其它",
  2: "有穿工服/其它",
  3: "摄像头歪曲",
  4: "整洁度（优）",
  5: "整洁度（良）",
  6: "整洁度（差）",
  7: "穿工服",
};
// 原纠正事件
const OldType = {
  1: "有人吸烟",
  2: "未穿工服",
  3: "摄像头歪曲",
  4: "整洁度（优）",
  5: "整洁度（良）",
  6: "整洁度（差）",
  7: "穿工服",
};
const principalWpost = {
  1: "工程管家",
  2: "项目经理",
};
// 接单情况
const receiveordersState = {
  0: "待接单",
  1: "已拒接",
  2: "已接单",
};
// 发货情况
const sendgoodsState = {
  0: "待处理",
  1: "已取消",
  2: "已发货",
  3: "已送达",
  4: "已验货",
  5: "已退货",
};
// 发货进度
const logisticsProgress = {
  0: "待处理",
  1: "发货中",
  2: "已送达",
  3: "已验收",
};
// 验收状态
const checkState = {
  // 0: "发货中",
  // 1: "已验收",
  // 2: "换货",
  // 3: "退货",
  0: "待验收",
  1: "部分验收",
  2: "已验收",
};
// 物料信息详情--验收结果
const acceptanceresults = {
  0: "发货中",
  1: "待验收",
  2: "已验收",
  3: "补货",
  4: "换货",
  5: "退货",
};
// 审核状态
const examinestate = {
  0: "待审核",
  1: "审核通过",
  2: "审核拒绝",
};
// 物流类型
const Logisticstype = {
  0: "未发货退货",
  1: "发货",
  2: "补货",
  3: "换货",
  4: "退换货",
  5: "退货",
};
// 物流状态
const Logisticsstatus = {
  0: "待处理",
  1: "已取消",
  2: "已完成",
  3: "部分退货",
  4: "全部退货",
};
// 物料状态
const cmMState = {
  1: "待下单",
  2: "已下单",
  3: "待发货",
  4: "已发货",
  5: "待验收",
  6: "已验收",
  7: "待退货",
};
// 退换货详情类型
const changeGoodsdetailsType = {
  0: "发货",
  1: "补货",
  2: "换货",
  3: "退货",
};
// 订单列表详情--配送方式
const deliveryMethodList = {
  1: "送货上门",
  2: "集中配送",
  3: "门店自取",
};
// 新建报价列表属性
const attributeList = {
  1: "套餐",
  2: "部分调整",
  3: "增加项",
  4: "减少项",
};
export function FBindStatus(value) {
  return BindStatusMap[value];
}

export function FNowork(value) {
  return NoworkMap[value] ? NoworkMap[value] : "--";
}
export function FSiteStatus(value) {
  return SiteStatusMap[value];
}
export function FWorkerType(value) {
  return WorkerTypeMap[value];
}
export function FDeviceStatus(value) {
  return DeviceStatusMap[value];
}
export function SiteNeatLevel(value) {
  return SiteNeatLevelMap[value];
}
export function AlarmEvents(value) {
  return AlarmEventsMap[value];
}
export function SiteAttendance(value) {
  return SiteAttendanceMap[value];
}
export function SiteDeviceStatus(value) {
  return SiteDeviceStatusMap[value];
}

export function SitCorrectStatus(value) {
  return SitcorrectStatus[value];
}
// 工地整洁度
export function SitNeatResult(value) {
  return SitneatResult[value];
}
// 纠正事件;
export function sitTypeLog(value) {
  return SitTypeLog[value];
}
// 原纠正事件
export function oldType(value) {
  return OldType[value];
}
export function PrincipalWpost(value) {
  return principalWpost[value];
}

export function ReceiveOrdersState(value) {
  return receiveordersState[value];
}
export function SendGoodsState(value) {
  return sendgoodsState[value];
}
export function LogisticsProgress(value) {
  return logisticsProgress[value];
}
export function CheckState(value) {
  return checkState[value];
}
export function AcceptanceResults(value) {
  return acceptanceresults[value];
}
export function ExamineState(value) {
  return examinestate[value];
}
export function LogisticsStatus(value) {
  return Logisticsstatus[value];
}
export function LogisticsType(value) {
  return Logisticstype[value];
}
export function CmMState(value) {
  return cmMState[value];
}
export function ChangeGoodsDetailsType(value) {
  return changeGoodsdetailsType[value];
}
export function DeliveryMethodList(value) {
  return deliveryMethodList[value];
}
export function AttributeList(value) {
  return attributeList[value];
}
